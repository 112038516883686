import { toRefs, reactive } from "vue";
import { env } from '@/helpers/config'
import Prismic from '@prismicio/client'

export const usePrismicAPI = () => {
  const state = reactive({
    page: [],
    loading: true,
    errored: false,
    gallery: null
  });

  const fetchData = async (payload) => {
      try {
        const client = await Prismic.client( env.PRISMIC_API_URL, { accessToken: env.PRISMIC_ACCESS_TOKEN })
        const res = await client.query(Prismic.Predicates.at('document.type', payload ));

        if (res) {
          if (res.results_size > 0) {
            state.page = res.results[0].data.body
          }
        }

      } catch (err) {
        state.errored = err
      } finally {
        state.loading = false
      }
  }

  const fetchDataByID = async (id) => {
    try {
      const client = await Prismic.client( env.PRISMIC_API_URL, { accessToken: env.PRISMIC_ACCESS_TOKEN })
      const res = await client.query(
         Prismic.Predicates.at('document.type', id )
      );

      console.log(res);

    } catch (e) {
      console.log(e);
    }
  }
  const fetchGallery = async (id) => {
    try {
      const client = await Prismic.client( env.PRISMIC_API_URL, { accessToken: env.PRISMIC_ACCESS_TOKEN })
      const res = await client.query(
         Prismic.Predicates.at('document.type', id )
      );

      if (res) {
        let  { results } = res;

        results[0].data.body.map( (slice) => {
          switch (slice.slice_type) {
            case 'gallery':
              state.gallery = slice.items;
              break;
            default:

          }
        });
      }

    } catch (e) {
      console.log(e);
    }
  }

  return {
    ...toRefs(state),
    fetchData,
    fetchDataByID,
    fetchGallery
  }
};
