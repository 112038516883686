<template>
  <div id="gallery" class="gallery">
    <div class="container">
        <h2 class="gallery-title">Gallery</h2>
    </div>
    <div class="container">
    <div class="gallery-wrap">
        <div class="tnsSlider" ref="tnsSlider">
            <div v-for="(item, key) in gallery" :key="key">
                <img :src="item.image.url" />
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, reactive, onMounted } from 'vue'
  import { tns } from 'tiny-slider/src/tiny-slider'

  import { usePrismicAPI } from '@/use/usePrismic';

  export default {
    name: 'Gallery',
    setup() {
      const tnsSlider = ref(null);
      const state = reactive({
        slider: null,
        gallery: []
      });

      const { fetchGallery, gallery } = usePrismicAPI();

      const getGalleryRepositories = async () => {
        await fetchGallery('home_renew');
        state.gallery = gallery
        renderSlider();
      }


      const renderSlider = () => {
        state.slider = tns({
          container:'.tnsSlider',
          items: 3,
          gutter: 20,
          autoplay: true,
          speed: 1000,
          nav: false,
          controls: true,
          arrowKeys: true,
          mouseDrag: true,
          autoWidth: true,
          autoHeight: true,
          controlsText:[`<span><svg width="64"
                          height="64"
                          viewBox="0 0 64 64"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          >
                              <circle cx="32.1879" cy="31.7152" r="31.5009" fill="#272727" />
                              <path
                                  d="M19.6174 31.2895C19.1728 31.7341 19.1728 32.4551 19.6174 32.8997L26.8633 40.1456C27.308 40.5903 28.0289 40.5903 28.4735 40.1456C28.9182 39.701 28.9182 38.9801 28.4735 38.5354L22.0327 32.0946L28.4735 25.6538C28.9182 25.2091 28.9182 24.4882 28.4735 24.0436C28.0289 23.5989 27.308 23.5989 26.8633 24.0436L19.6174 31.2895ZM43.1942 30.956L20.4225 30.956L20.4225 33.2332L43.1942 33.2332L43.1942 30.956Z"
                                  fill="#FEFCF9"
                              />
                        </svg></span>`,`<svg width="64"
                        height="64"
                        viewBox="0 0 64 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <circle
                            r="31.5009"
                            transform="matrix(-1 0 0 1 32.1879 31.7152)"
                            fill="#272727"
                        />
                        <path
                            d="M44.7583 31.2897C45.203 31.7344 45.203 32.4553 44.7583 32.8999L37.5124 40.1459C37.0678 40.5905 36.3469 40.5905 35.9022 40.1459C35.4576 39.7012 35.4576 38.9803 35.9022 38.5357L42.343 32.0948L35.9022 25.654C35.4576 25.2094 35.4576 24.4885 35.9022 24.0438C36.3469 23.5992 37.0678 23.5992 37.5124 24.0438L44.7583 31.2897ZM21.1815 30.9563L43.9532 30.9563L43.9532 33.2334L21.1815 33.2334L21.1815 30.9563Z"
                            fill="#FEFCF9"
                        />
                        </svg>`],
          responsive: {
            350: {
                items: 1
            },
            500: {
                items: 3
            }
          },

          });
        };

        onMounted(() => {
          getGalleryRepositories();

        })

      return {
        state,
        tnsSlider,
        gallery
      }
    }
  }
</script>

<style lang="scss">
  .gallery {
    margin-top: 80px;
    margin-bottom: 219px;
  }

  h2.gallery-title {
    font-size: 35px;
    line-height: 53px;
    font-weight: 500;
    text-align: center;
  }

  .gallery_wrap {
    margin-top: 40px;
  }

  .gallery-block {
    margin-bottom: 40px;
  }

  .tns-item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    font-size: 0;
    height: 334px;

    img {
        height: 100%;
        object-fit: cover;
    }
  }

  .tns-outer {
    position: relative;

    > button {
      display: none;
    }
  }

  .tns-controls {
    background: red;

    button {
        position: absolute;
        background: transparent;
        width: 63px;
        height: 63px;
        border-radius: 50%;
        border: none;

        &:nth-child(1) {
          top: 45%;
          left: -10px;
          z-index: 1;
        }

        &:nth-child(2) {
          top: 45%;
          right: -10px;
          z-index: 1;
        }

        svg {
          width: 32px;
          height: 32px;
        }
    }
  }



  @media only screen and (min-width: $breakpoint-med) {
    .tns-nav {
      bottom: 40px;
    }

    .tns-controls {
      button {
        svg {
          width: 64px;
          height: 64px;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .tns-controls {
      button {
        &:nth-child(1) {
          left: -20px;
        }
      }
    }
  }

</style>
